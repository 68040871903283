<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        تحديث مدينة
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCityFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="اسم المدينة "
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="City Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCityForm.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="الرابط "
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="In-Link Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCityForm.link_id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="الترتيب "
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="In-Slider Order"
                rules="required"
              >
                <b-form-input
                  id="slider_order"
                  v-model="addCityForm.slider_order"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Tab صورة
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="`https://ar.lpcentre.com/${addCityForm.tab_image}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewE2.src = Tabimage
                            addCityForm.tab_image=''
                    "
                  >
                    ازالة الصورة
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group

                      label-for="blog-tab-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image"
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="addCityForm.tab_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="اختر ملف"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="نص بديل للصورة"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addCityForm.alt_tab_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Icon صورة
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEl"
                      :src="`https://ar.lpcentre.com/${addCityForm.tab_image}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewEl.src = Tabimage
                            addCityForm.icon_image=''
                    "
                  >
                    ازالة الصورة
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Icon Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Icon Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="addCityForm.icon_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="اختر ملف"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="نص بديل للصورة"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id=" alt_icob_image"
                        v-model="addCityForm.alt_icon_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              رجوع
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,

  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import Vue from 'vue'
import store from '@/store'

export default {
  setup() {
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const { route } = useRouter()
    const { id } = route.value.params
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)
    console.log(id)
    store.dispatch('cities/GetCity', { id }).then(response => {
      store.commit('cities/GET_CITY', response?.data)
    })

    const CityForm = computed(() => store.state.cities.city)
    const addCityForm = reactive(CityForm)
    console.log('CityForm', CityForm)
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCityFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      console.log(addCityForm)
      formData.append('name', addCityForm.value.name)
      formData.append('id', addCityForm.value.id)
      formData.append('icon_image', addCityForm.value.icon_image)
      formData.append('slider_order', addCityForm.value.slider_order)
      formData.append('link_id', addCityForm.value.link_id)
      formData.append('tab_image', addCityForm.value.tab_image)
      formData.append(' icon_image', addCityForm.value.icon_image)
      formData.append('alt_tab_image', addCityForm.value.alt_tab_image)
      formData.append('_method', 'put')
      formData.append('alt_icon_image', addCityForm.value.alt_icon_image)

      //  addCityFormvalidate.value.validate().then(success => {
      //         if (success) {
      store.dispatch('cities/UpdateCity', { id, formData }).then(response => {
        Vue.swal({
          icon: 'success',
          title: 'تم التحديث',
          confirmButtonText: 'حسنا',

          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
      // }})
    }
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCityForm,
      inputImageRenderer,
      addCityFormvalidate,
      id,
      CityForm,

      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,

    BForm,

    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
